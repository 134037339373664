import './App.css';
import ResetPassword from './Pages/ResetPassword/ResetPassword';

function App() {
  return (
    <>
    <ResetPassword />
    </>
  );
}

export default App;
